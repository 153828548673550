import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
	ImageUrlFormatter,
	Ordinal,
	convertTime,
	formatDateAndTime,
} from "../../resources/constants";
import { titleCase } from "title-case";
import * as DOMPurify from "dompurify";

function HomeEvents(props) {
	const [eventList, setEventsList] = useState(
		props.eventList.length > 0 ? props.eventList : []
	);

	return (
		<div>
			{eventList.length > 0 && (
				<section className="event pt-50 pb-90 p-relative fix">
					<div className="container">
						<div className="row">
							<div className="col-lg-12 p-relative">
								<div
									className="section-title center-align mb-50 text-center wow fadeInDown animated"
									data-animation="fadeInDown"
									data-delay=".4s"
								>
									<h5>
										<i className="fal fa-graduation-cap" /> Events
									</h5>
									<h2>Upcoming Events</h2>
								</div>
							</div>
						</div>
						<div className="row">
							{eventList.slice(0, 3).map((x, i) => (
								<div key={i} className="col-lg-4 col-md-6 d-flex">
									<div className="event-item mb-30 hover-zoomin d-flex flex-column h-100">
										{/* Image Section */}
										<div className="thumb">
											<Link to={`/event/${x.Slug}`}>
												<img
													src={ImageUrlFormatter(x.ImagePath, "event_uploads")}
													alt="event-img"
													className="img-fluid w-100"
													style={{ height: "200px", objectFit: "cover" }}
												/>
											</Link>
										</div>

										{/* Event Content */}
										<div className="event-content d-flex flex-column flex-grow-1 p-3">
											<div className="date">
												<strong>
													{new Date(x.EventDate).getDate()}
													<sup>{Ordinal(new Date(x.EventDate).getDate())}</sup>
												</strong>
												{formatDateAndTime(x.EventDate, "month_and_year")}
											</div>

											<h3 className="mt-2">
												{x.titleCase !== null && (
													<Link to={`/event/${x.Slug}`}>
														{x.Title.length > 50
															? titleCase(x.Title.substr(0, 50))
															: titleCase(x.Title)}
													</Link>
												)}
											</h3>

											<p
												dangerouslySetInnerHTML={{
													__html: DOMPurify.sanitize(
														x.Description.substr(0, 100)
													),
												}}
												className="mt-2"
											/>

											{/* Time and Location (Always at the bottom) */}
											<div className="time mt-auto">
												{convertTime(x.StartTime)} - {convertTime(x.EndTime)}
												<i className="fal fa-long-arrow-right mx-2" />
												<strong>{x.Location}</strong>
											</div>
										</div>
									</div>
								</div>
							))}

							<div className="d-flex justify-content-center mt-5">
								<Link to="/events" className="btn ss-btn smoth-scroll">
									More events <i className="fal fa-long-arrow-right" />
								</Link>
							</div>
						</div>
					</div>
				</section>
			)}
		</div>
	);
}

const mapStateToProps = (state) => {
	return {
		eventList: state.EventDetails,
	};
};

export default connect(mapStateToProps, null)(HomeEvents);
