import React, { useEffect, useState } from 'react'
import Bredcom from "../../../component/Bredcom/Main";
import { Link } from "react-router-dom";
import axios from "axios";
import { serverLink, shortCode } from "../../../resources/constants";
import LeadershipModal from "../modal/modal";
import SpinnerLoader from "../../../component/common/spinner-loader";
function BoardMembers(props)
{
    const [boards, setBoard] = useState([]);
    const [loading, setLoading] = useState(true);
    const [det_, setDet] = useState({
        Name: "",
        Image: "",
        Description: "",
        IsOpen: false
    })


    async function getBoardMembers()
    {
        await axios
            .get(`${serverLink}leadership/board/list`)
            .then((response) =>
            {
                setBoard(response.data);
                setLoading(false);
            })
            .catch((error) =>
            {
                console.log("NETWORK ERROR", error);
            })
    }

    useEffect(() =>
    {
        getBoardMembers();
    }, []);

    const canvasMenuAdd = (board) =>
    {
        setDet({
            ...det_,
            Name: `${board.Title} ${board.FullName}`,
            Image: `${serverLink}public/uploads/${shortCode}/leaderships_uploads/${board.ImagePath}`,
            IsOpen: true,
            Description: <p dangerouslySetInnerHTML={{ __html: board.Description }} />
        })
    };

    const openModal = () =>
    {
        const modalContainer = document.getElementById('modalContainer');
        modalContainer.style.display = 'block';
    }

    return (loading ? <SpinnerLoader /> :
        <>
            <Bredcom title="Home" subtitle="Board Members" />
            <section className="team-area2 fix p-relative pt-120 pb-80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 p-relative">
                            <div className="section-title center-align mb-50 text-center wow fadeInDown animated" data-animation="fadeInDown" data-delay=".4s" >
                                <h5>
                                    <i className="fal fa-user" /> Board Members
                                </h5>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {
                            boards.map((board, index) =>
                            (
                                <div key={index} className={`text-center ${index === 0 ? "col-md-12 d-block" : "col-xl-4 col-md-6"}  `}>
                                    <div className="single-team mb-40">
                                        <div className="team-thumb">
                                            <div className="brd">
                                                <Link onClick={() => { canvasMenuAdd(board); openModal(); }}>
                                                    <img src={`${serverLink}public/uploads/${shortCode}/leaderships_uploads/${board.ImagePath}`}
                                                        alt={board.FullName}
                                                        className="img-thumbnail"
                                                        style={{
                                                            height: 350,
                                                            width: 400,
                                                        }} />
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="team-info">
                                            <h5>
                                                <Link onClick={() => { canvasMenuAdd(board); openModal(); }}>{board.Title} {board.FullName}</Link>
                                            </h5>
                                            <p>{board.Designation}</p>
                                            <p style={{ cursor: 'pointer' }} className="text-center mb-5"
                                                data-toggle="modal" data-target=".bd-example-modal-lg"
                                                onClick={() => { canvasMenuAdd(board); openModal(); }}
                                            >View profile</p>
                                        </div>
                                    </div>
                                </div>
                            )
                            )
                        }

                    </div>
                </div>
                <LeadershipModal
                    IsOpen={det_.IsOpen}
                    Name={det_.Name}
                    Image={det_.Image}
                    Description={det_.Description}
                />
            </section>

        </>
    )
}

export default BoardMembers