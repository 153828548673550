import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
	ImageUrlFormatter,
	Ordinal,
	formatDateAndTime,
} from "../../resources/constants";
import { titleCase } from "title-case";
import * as DOMPurify from "dompurify";

function HomeNews(props) {
	const [newsList] = useState(props.newsList.length > 0 ? props.newsList : []);

	return (
		<div>
			{newsList.length > 0 && (
				<section className="event pt-50 pb-90 p-relative fix">
					<div className="container">
						<div className="row">
							<div className="col-lg-12 p-relative">
								<div
									className="section-title center-align mb-50 text-center wow fadeInDown animated"
									data-animation="fadeInDown"
									data-delay=".4s"
								>
									<h5>
										<i className="fal fa-graduation-cap" /> Events
									</h5>
									<h2>Events</h2>
								</div>
							</div>
						</div>
						<div className="row">
							{newsList.slice(0, 3).map((x, i) => (
								<div
									key={i}
									className="col-lg-4 col-md-6 wow fadeInUp animated"
									data-animation="fadeInUp"
									data-delay=".4s"
								>
									<div className="event-item mb-30 hover-zoomin d-flex flex-column h-100">
										<div className="thumb">
											<Link to={`/news/${x.Slug}`}>
												<img
													src={ImageUrlFormatter(x.ImagePath, "news_uploads")}
													alt="news"
													style={{
														minHeight: "250px",
														maxHeight: "250px",
														width: "100%",
													}}
												/>
											</Link>
										</div>
										<div className="event-content flex-grow-1 d-flex flex-column">
											<div className="date">
												<strong>
													{new Date(x.InsertedDate).getDate()}
													<sup>
														{Ordinal(new Date(x.InsertedDate).getDate())}
													</sup>
												</strong>
												{formatDateAndTime(x.InsertedDate, "month_and_year")}
											</div>
											<h4>
												<Link to={`/news/${x.Slug}`}>
													{x.Title.length > 50
														? `${titleCase(x.Title.substring(0, 50))}...`
														: titleCase(x.Title)}
												</Link>
											</h4>
											<p
												dangerouslySetInnerHTML={{
													__html: DOMPurify.sanitize(
														`${x.Description.substring(0, 80)}...`
													),
												}}
											/>
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
				</section>
			)}
		</div>
	);
}

const mapStateToProps = (state) => ({
	newsList: state.NewsDetails,
});

export default connect(mapStateToProps)(HomeNews);
