import React, { useEffect, useState } from 'react';
import Bredcom from "../../../component/Bredcom/Main";
import { Link } from "react-router-dom";
import axios from "axios";
import { serverLink, shortCode } from "../../../resources/constants";
import SpinnerLoader from "../../../component/common/spinner-loader";

function GalleryView(props) {
    const [galleryCat, setGalleryCat] = useState([]);
    const [subCat, setSubCat] = useState([]);
    const [galleryImageMui, setGalleryImageMui] = useState([]);
    const [current, setCurrent] = useState([]);
    const [loading, setLoading] = useState(false);

    // Detect if the media is a video
    const isVideo = (url) => /\.(mp4|webm|ogg)$/i.test(url);

    async function getGalleryImages() {
        setLoading(true);
        try {
            const response = await axios.get(`${serverLink}gallery/list`);
            const data = response.data;

            let galleryImages = [];
            let categories = [];
            let subcategories = [];

            data.forEach((gallery) => {
                const imagePath = gallery.ImagePath ?
                    (gallery.ImagePath.includes("simplefileupload")
                        ? gallery.ImagePath
                        : `${serverLink}public/uploads/${shortCode}/gallery/${gallery.ImagePath}`)
                    : "";

                galleryImages.push({
                    categoryName: gallery.CategoryName,
                    subCategoryName: gallery.SubCategoryName,
                    title: gallery.ImageTitle,
                    original: imagePath,
                    thumbnail: imagePath,
                });

                categories.push(gallery.CategoryName);
                subcategories.push({
                    subCatName: gallery.SubCategoryName,
                    catName: gallery.CategoryName,
                });
            });

            setGalleryImageMui(galleryImages);
            setCurrent(galleryImages);

            const uniqueCategories = [...new Set(categories)];
            setGalleryCat(uniqueCategories);
            setSubCat(subcategories);
        } catch (error) {
            console.error("SERVER ERROR", error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getGalleryImages();
        document.getElementById("View_All").classList.add('active');
    }, []);

    function previewMedia(url) {
        const previewContent = document.getElementById("preview-content");
        const contentHTML = isVideo(url)
            ? `<video controls autoplay height="250"><source src="${url}" type="video/mp4" />Your browser does not support the video tag.</video>`
            : `<img src="${url}" alt="Preview" />`;

        previewContent.innerHTML = contentHTML;
        document.getElementById("preview-container").style.display = "block";
    }

    function closePreview() {
        document.getElementById("preview-container").style.display = "none";
    }

    const Filter = (category) => {
        document.querySelectorAll('.active').forEach((el) => el.classList.remove('active'));

        if (category === "View All") {
            document.getElementById("View_All").classList.add('active');
            setCurrent(galleryImageMui);
        } else {
            const filtered = galleryImageMui.filter(item => item.categoryName === category);
            document.getElementById(category.replace(/ /g, '_')).classList.add('active');
            setCurrent(filtered);
        }
    };

    return loading ? <SpinnerLoader /> : (
        <>
            <Bredcom title="Home" subtitle="Gallery" />
            <section className="team-area2 fix p-relative pt-120 pb-80">
                <div className="container">
                    <div className="portfolio">
                        <div className="row align-items-end">
                            <div className="col-lg-12">
                                <div className="my-masonry text-center mb-50 wow fadeInRight animated" data-animation="fadeInRight" data-delay=".4s">
                                    <div className="button-group filter-button-group">
                                        <button id="View_All" data-filter="*" onClick={() => Filter("View All")}>View All</button>
                                        {galleryCat.map((item, index) => (
                                            <button
                                                key={index}
                                                id={item.replace(/ /g, '_')}
                                                data-filter={`.${item}`}
                                                onClick={() => Filter(item)}
                                            >
                                                {item}
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="grid col3 row wow fadeInUp animated" data-animation="fadeInUp" data-delay=".4s">
                            {current.map((item, index) => (
                                <div className="grid-item financial col-4" key={index}>
                                    <Link onClick={() => previewMedia(item.thumbnail)}>
                                        <figure className="gallery-image">
                                            {isVideo(item.thumbnail) ? (
                                                <video className="video-thumb" height={280} controls>
                                                    <source src={item.thumbnail} type="video/mp4" />
                                                    Your browser does not support the video tag.
                                                </video>
                                            ) : (
                                                <img className="img-thumb" src={item.thumbnail} alt={item.title} />
                                            )}
                                        </figure>
                                    </Link>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>

            <div id="preview-container" style={{ display: 'none', height: '450px' }}>
                <span className="close-btn" onClick={closePreview}>&times;</span>
                <div id="preview-content"></div>
            </div>
        </>
    );
}

export default GalleryView;
